<template>
  <svg
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.65996 0.614682C6.01691 0.614682 6.30627 0.904045 6.30627 1.26099L6.30627 10.7402C6.30627 11.0972 6.01691 11.3865 5.65996 11.3865C5.30301 11.3865 5.01365 11.0972 5.01365 10.7402L5.01365 1.26099C5.01365 0.904045 5.30301 0.614682 5.65996 0.614682Z"
    />
    <path
      d="M0.267594 6.0006C0.267595 5.64366 0.556958 5.35429 0.913906 5.35429L10.3931 5.35429C10.7501 5.35429 11.0394 5.64366 11.0394 6.0006C11.0394 6.35755 10.7501 6.64691 10.3931 6.64691L0.913905 6.64692C0.556958 6.64691 0.267595 6.35755 0.267594 6.0006Z"
    />
  </svg>
</template>
